<template>
    <div class="tc-heading-style6">
  	    <h3 class="heading-inner">{{ text }}</h3>	
  	</div>
</template>

<script>
    
    export default {
        props: {
            text: String
        }
    }
</script>